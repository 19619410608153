const menu = {
  Home: [
    { id: 'dataSearch', name: '平台概览', icon: '', parentId: '', route: '/index/dataSearch' },

    { id: 'home', name: '审核管理', icon: '', parentId: '', route: '' },
    { id: 'home1', name: '企业认证', icon: '', parentId: 'home', route: '/index/companyAudit' },
    { id: 'home2', name: '个人认证', icon: '', parentId: 'home', route: '/index/registerAudit' },
    { id: 'home3', name: '发布审核', icon: '', parentId: 'home', route: '/index/releaseAudit' },

    { id: 'feedback', name: '留言反馈', icon: '', parentId: '', route: '/index/inbox' },

    { id: 'release', name: '需求互动', icon: '', parentId: '', route: '' },
    { id: 'release1', name: '需求互动', icon: '', parentId: 'release', route: '/index/release' },
    { id: 'release3', name: '互动评论', icon: '', parentId: 'release', route: '/index/comment' },

    { id: 'news', name: '资讯公告', icon: '', parentId: '', route: '' },
    { id: 'news1', name: '资讯管理', icon: '', parentId: 'news', route: '/index/news' },
    { id: 'news3', name: '通知公告', icon: '', parentId: 'news', route: '/index/notice' },

    { id: 'tech', name: '产品技术', icon: '', parentId: '', route: '' },
    { id: 'tech1', name: '产品技术', icon: '', parentId: 'tech', route: '/index/tech' },
    { id: 'tech2', name: '科研成果', icon: '', parentId: 'tech', route: '/index/achievement' },
    { id: 'tech3', name: '专家展示', icon: '', parentId: 'tech', route: '/index/expert' }
  ],
  Vip: [
    { id: 'vip1', name: '企业库', icon: '', parentId: '', route: '/index/companyVip' },
    { id: 'vip2', name: '个人库', icon: '', parentId: '', route: '/index/userVip' },
  ],
  Company: [
    { id: 'release', name: '需求互动', icon: '', parentId: '', route: '/index/companyRelease' },
    { id: 'tech', name: '产品技术', icon: '', parentId: '', route: '' },
    { id: 'tech1', name: '产品技术', icon: '', parentId: 'tech', route: '/index/tech' },
    { id: 'tech2', name: '科研成果', icon: '', parentId: 'tech', route: '/index/achievement' },
    { id: 'tech3', name: '技术专家', icon: '', parentId: 'tech', route: '/index/expert' },

    { id: 'companyUser', name: '企业人员', icon: '', parentId: '', route: '/index/companyUserAudit' },
    { id: 'companyDetail', name: '企业信息', icon: '', parentId: '', route: '/index/companyDetail' }
  ],
  App: [
    { id: 'setting1', name: '轮播图', icon: '', parentId: '', route: '/index/banner' },
    { id: 'setting2', name: '联动服务', icon: '', parentId: '', route: '/index/link' },
    { id: 'setting3', name: '互动类型', icon: '', parentId: '', route: '/index/releaseType' },
    { id: 'setting4', name: '会员标签', icon: '', parentId: '', route: '/index/tagVip' },
    { id: 'setting5', name: '行业类别', icon: '', parentId: '', route: '/index/industryTag' },
    { id: 'setting6', name: '资讯类型', icon: '', parentId: '', route: '/index/newsType' }
    // { id: 'setting3', name: '隐私条款', icon: '', parentId: '', route: '/index/policy' }
  ],
  System: [
    { id: 'system1', name: '人员管理', icon: '', parentId: '', route: '/index/systemUser' },
    { id: 'system2', name: '系统日志', icon: '', parentId: '', route: '/index/log' },
    { id: 'system3', name: '回收站', icon: '', parentId: '', route: '/index/recycle' }
  ]
};
export default menu;
